// Import dependencies
import React from "react"

// Import styles
import './section-separator.css'


/*
    Component that separates two sections
*/
const SectionSeparator = () => (
  <div className='ss__container' />
)

export default SectionSeparator
