// Import dependencies
import React from "react"

// Import styles
import "./header.css"

// Import icons
import CartComponent from "../cart/cart"
import HeaderDesktop from "./headerDesktop"
import HeaderMobile from "./headerMobile"
import StatisticsPopup from "../form/forms/statisticsPopup";
import {navigate} from "gatsby";
// Import redux
import {connect} from "react-redux"

import {getFirebase} from "../../api/firebase"
import GatsbyImage from "gatsby-image"

/*
    Header component
*/
class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            cartOpen: false,
            popupOpen: false,
            isMember: false,
            headerExtra: true,
        }
        this.toggleCart = this.toggleCart.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
    }

    toggleCart() {
        this.state.cartOpen
            ? this.setState({cartOpen: false})
            : this.setState({cartOpen: true})
    }

    togglePopup () {
        this.setState({
            popupOpen: !this.state.popupOpen
        });
    }

    componentDidMount() {
        if (this.props.userToken) {
            const app = import("firebase/app")
            const firestore = import("firebase/firestore")

            Promise.all([app, firestore]).then(([firebase]) => {
                getFirebase(firebase)
                    .firestore()
                    .collection("users")
                    .doc(this.props.userToken.uid)
                    .onSnapshot(doc => {
                        if (doc.data())
                            this.setState({isMember: doc.data().membership_info.is_member})
                    })
            })
        }
    }

    render() {
      if (!this.props.quotePage) {
        return (
            <>
                <header className={this.state.cartOpen ? "header__container header__open" : "header__container"}>
                {/* Main header component */}
                <HeaderDesktop
                    onClick={this.toggleCart}
                    isMember={this.state.isMember}
                    HomeIcons={this.props.HomeIcons}
                    shopPage={this.props.shopPage}
                />
                <HeaderMobile
                    onClick={this.toggleCart}
                    isMember={this.state.isMember}
                />
                <CartComponent togglePopup={this.togglePopup} showCart={this.toggleCart} styles={this.state.cartOpen ? "cart__open cart__wrapper" : "cart__wrapper"}/>
                <StatisticsPopup popupOpen={this.state.popupOpen} togglePopup={this.togglePopup} content="coin"/>
                </header>
            </>
        )
      }  else {
        return (
            <header className={this.props.open ? "header__container header__open" : "header__container"}>
            {/* Main header component */}
                <HeaderDesktop
                    onClick={this.props.openDrawer}
                    isMember={this.state.isMember}
                    HomeIcons={this.props.HomeIcons}
                    shopPage={this.props.shopPage}
                />
                <HeaderMobile
                    onClick={this.props.openDrawer}
                    isMember={this.state.isMember}
                />
                <CartComponent togglePopup={this.togglePopup} showCart={this.props.openDrawer} styles={this.props.open ? "cart__open cart__wrapper" : "cart__wrapper"}/>
                <StatisticsPopup popupOpen={this.state.popupOpen} togglePopup={this.togglePopup} content="coin"/>


            </header>
        )
      }


    }
}

// Connect redux to component
function mapStateToProps(state) {
    let userToken = {userToken: state.user.userToken, isHeaderVisible: state.headerState.isHeaderVisible}
    return userToken;
}

export default connect(mapStateToProps)(Header)
