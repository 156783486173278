/**
 * Get quote summary
 */
/*
export const getCartQuote = (cart, generalCart) =>
    Math.round(
    (
      cart.reduce(
          (acc, item) =>
            item.type == "member" ?
              item.promo_code_value ?
                  acc + item.promo_code_value :
                  acc + parseFloat(item.quote ? item.quote : 0)
                  /*item.quote.split(",").join("")*/
//            : 0,
  //        0
    //  ) + generalCart.reduce((acc, item) =>
      //      item.type == "member" ?
            //    item.promo_code_value ?
          //          acc + item.promo_code_value :
        //            acc + parseFloat(item.total_price)
      //      : 0
    //    ,0
  //    )
//    )
//    * 100) / 100;

export const getCartQuote = (cart, generalCart) =>
Math.round(
(
    cart.filter(item => item.type == "member").reduce((acc, item) => acc + parseFloat(item.quote), 0)
    + generalCart.filter(item => item.type == "member" || item.isBulk ).reduce((acc, item) => acc + parseFloat(item.total_price), 0)
) * 100) / 100;

export const getCartCoin = (generalCart) => 
    (
        generalCart.filter(item => item.type == "standard" && !item.isBulk).reduce((acc, item) => acc + parseInt(item.total_price), 0)
    );


export const getCartWeight = (cart, generalCart) =>

    cart.reduce(
        (acc, item) =>
                acc + parseFloat(item.weight ? /*item.quote.split(",").join("")*/ item.weight : 0),
        0
    ) + generalCart.reduce((acc, item) =>
            acc + parseFloat(item.weight), 0)
    ;

export const toTitleCase = (str) => {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}
