// Import dependencies
import React from "react";

// Import components
import {
  ParagraphText,
  NewBoxComponent,
} from "../../styled-components/UILibrary";

// Import styles
import "./cart-item.css";
import { Constants } from "../../constants/Constants";

// Import redux
import { connect } from "react-redux";
import { removeItemFromCart } from "../../redux/actions/generalCartAction";
import Close from "../../assets/icons/close.svg";

/*
    Cart Item component for General Recycling Shopping Cart component
*/
const GeneralCartItem = ({
  item,
  quantity,
  quote,
  total_price,
  id,
  forReview = false,
  margin,
  dispatch,
  checkoutPage = false,
  reCheckChallenges,
  index
}) => (
  <div className="cart_item__wrapper">
    <div className="cart_item__container">
      <div className="cart_items">
        <div className="cart_item__section" style={forReview ? {width: "60%"} : {}}>
          <p className="cart_item_product_name">{item}</p>
          {!checkoutPage &&
            <>
              <p className="cart_item_info">{quantity}</p>
              <p className="cart_item_info">{quote}</p>
            </>
          }
          {checkoutPage &&
            <p className="cart_item_remove"  onClick={() => {dispatch(removeItemFromCart(index)); reCheckChallenges();}} >Remove</p>}
          {(!forReview && !checkoutPage) && (
              <p className="cart_item_info">
                {total_price}
              </p>
          )}
        </div>
        {checkoutPage &&
          <><ParagraphText className="checkout_cart_infos" lineHeight="18px" size={12} style={{color: "#000000"}}>
            {quantity}
          </ParagraphText>
          <ParagraphText className="checkout_cart_infos" lineHeight="18px" size={12} style={{color: "#000000"}}>
            {quote}
          </ParagraphText></>
        }

        {!checkoutPage &&
          <svg className="cart_item_remove_icon" onClick={() => dispatch(removeItemFromCart(index))} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.4501 0.565078C18.8651 -0.0199215 17.9201 -0.0199215 17.3351 0.565078L10.0001 7.88508L2.66508 0.550078C2.08008 -0.0349219 1.13508 -0.0349219 0.550078 0.550078C-0.0349219 1.13508 -0.0349219 2.08008 0.550078 2.66508L7.88508 10.0001L0.550078 17.3351C-0.0349219 17.9201 -0.0349219 18.8651 0.550078 19.4501C1.13508 20.0351 2.08008 20.0351 2.66508 19.4501L10.0001 12.1151L17.3351 19.4501C17.9201 20.0351 18.8651 20.0351 19.4501 19.4501C20.0351 18.8651 20.0351 17.9201 19.4501 17.3351L12.1151 10.0001L19.4501 2.66508C20.0201 2.09508 20.0201 1.13508 19.4501 0.565078Z" fill="#828282"/>
          </svg>
        }
      </div>
    </div>
  </div>
);

// Connect redux to component
export default connect()(GeneralCartItem);
