/*
    This file contains constants used
    throughout the website
*/
export const Constants = {
  // Main colours
  mainGreen: "#55BC83",
  mainRed: "#EF5F5A",
  shadow: "#DCE6ED",
  lightGray: "#F6F8FA",
  // Font Colours
  fontColour: "#8397A4",
  titleColour: '#120821',
  // Font Sizes
  minParagraphText: 16,
  maxParagraphText: 20,
  minTitle: 28,
  maxTitle: 40,
  minSubTitle: 23,
  maxSubTitle: 26,
  minQuoteTitle: 55,
  maxQuoteTitle: 100,
}
