// Import dependencies
import React from "react"
import { navigate } from "gatsby"

// Import components
import "./quote.css"
/*
    Forgot Password Component
*/
export default class StatisticsPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {

      return (
        <div className="popup_container"  style={this.props.popupOpen ? {} : {display: "none"}}>
        <div className="popup small-popup" style={{paddingTop: 80}}>
        <div className="brand-help__content statistic_popup">
          <h1 sclassName="" style={this.props.success ? {display: "none"} : {}}>
            {this.props.content == "statistics" && "Statistics"}
            {this.props.content == "condition" && "Conditions"}
            {this.props.content == "terms" && "Terms & Conditions"}
            {this.props.content == "coin" && "What are Reinin Coins?"}
            {this.props.content == "other_items" && "What are “Other” items?"}
          </h1>
          {this.props.content == "statistics" &&
          <>
            <p>Our algorithms calculate the recyclable value of your item using data from multiple sources.
            <br/><br/><b>Demand score</b>
            <br/>A measure of the current desirability of your item
            <br/><br/><b>Reusability score</b>
            <br/>A measure of how useful your item is, either as a whole, or in terms of its components.
            <br/><br/><b>Recyclable value range</b>
            <br/>Our price offer range, which reflects both the raw material value and component value of your item.
            <br/><br/><b>Price certainty</b>
            <br/>An indication of how sure our pricing algorithm was when calculating the value of your item.</p>
          </>
          }
          {this.props.content == "condition" &&
          <>
            <p><b>Looks new</b>
            <br/>May have very light signs of use which are not clearly noticeable to a user.
            <br/><br/><b>Clearly used</b>
            <br/>Has easily noticeable blemishes that don't affect normal usage.
            <br/><br/><b>Not good</b>
            <br/>Has missing parts, pixel fading, or damage that affects normal usage.</p>
          </>
          }
          {this.props.content == "terms" &&
          <>
            <p>This is a provisional quote that may be adjusted after we have received and reviewed your items.</p>
            <p>The quote shown is the total after collection fees, promotions, and donations have been applied.</p>
            <p>The value of any item(s) missing from your order shall be deducted from the total quote.</p>
            <p style={{marginBottom: 0}}>Do not include extra items that aren’t declared in this order form, as they will not be quoted.</p>
          </>
          }
          {this.props.content == "other_items" &&
            <p>The “Other” label refers to any product that belongs to this category of items. This label is a catch-all term that allows you to sell a product to us even if you were not able to find its brand or its specific model on our website.</p>
          }
          {this.props.content == "coin" &&
          <p>Reinin Coins are our official virtual currency. You can earn Reinin Coins under 2 scenarios: (1) You recycle items for which the offer is priced in Reinin Coins; or (2) You complete a Challenge for which the reward is Reinin Coins. You do not need to be a paying member in order to earn Reinin Coins.
            <br/><br/>100 Reinin Coins is equivalent to $1. You can convert Reinin Coins into cash by logging in to your account and going to the Coins balance tab.
          </p>
          }
          <div className="close_popup" onClick={this.props.togglePopup}>Got it</div>
        </div>
        </div>
        </div>
      )
  }
}
