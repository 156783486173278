// Import dependencies
import React from "react";
import { Link } from "gatsby";

// Import components
import { TitleText } from "../../styled-components/UILibrary";

// Import styles
import "./mobile-menu.css";
import "./header.css";

// Import icons
import Close from "../../assets/icons/close.svg";
import Lock from "../../assets/icons/lock.svg";

// Import from Redux
import { connect } from "react-redux";

/*
    Mobile Menu component
*/
const MobileMenu = ({ onClick, styles, backgroundStyles, isMember, userToken }) => (
<div>
  <div className={backgroundStyles} style={{left: "0"}}>
    <div className="ant-drawer-mask"></div>
  </div>
  <div className={styles}>
   <div tabindex="-1" className="ant-drawer ant-drawer-left full-width-mobile" style={{left: "0"}}>
      <div className="ant-drawer-content-wrapper full-width-mobile full-width-mobile" style={{width: "256px"}}>
         <div className="ant-drawer-content">
            <div className="ant-drawer-wrapper-body">
               <div className="ant-drawer-header-no-title">
                  <button aria-label="Close" className="ant-drawer-close">
                     <i aria-label="icon: close" className="anticon anticon-close">
                        <svg viewBox="64 64 896 896" focusable="false" className="" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                           <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                        </svg>
                     </i>
                  </button>
               </div>
               <div className="ant-drawer-body">
                  <div className="category-drawer__container-close-button" onClick={() => onClick("mobile-menu__content", "ant-drawer ant-drawer-left menu-background")}>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M17.4 0.613295C16.88 0.0932946 16.04 0.0932946 15.52 0.613295L8.99996 7.11996L2.47996 0.599961C1.95996 0.079961 1.11996 0.079961 0.599961 0.599961C0.079961 1.11996 0.079961 1.95996 0.599961 2.47996L7.11996 8.99996L0.599961 15.52C0.079961 16.04 0.079961 16.88 0.599961 17.4C1.11996 17.92 1.95996 17.92 2.47996 17.4L8.99996 10.88L15.52 17.4C16.04 17.92 16.88 17.92 17.4 17.4C17.92 16.88 17.92 16.04 17.4 15.52L10.88 8.99996L17.4 2.47996C17.9066 1.97329 17.9066 1.11996 17.4 0.613295V0.613295Z" fill="#828282"/>
                  </svg>
                  </div>
                  <div>
                     <div className="category-drawer__container-top-label category-drawer__container-top-label-department-level">
                        <div className="category-drawer__container-top-label-text">MENU</div>
                     </div>
                     <div className="category-drawer__container-top-label-divider">
                        <div className="ant-divider ant-divider-horizontal" role="separator"></div>
                     </div>
                     <div>
                        <Link to="/" aria-label="Home"><div onClick={() => onClick("mobile-menu__content", "ant-drawer ant-drawer-left menu-background")} className="category-drawer__container-menu-title category-drawer__container-menu-title-clickable category-drawer__container-menu-title-department-level">
                           <div className="category-drawer__container-menu-title-text category-drawer__container-menu-title-text-department-level">Home</div>
                        </div></Link>
                        <Link to="/how-it-works"><div onClick={() => onClick("mobile-menu__content", "ant-drawer ant-drawer-left menu-background")} className="category-drawer__container-menu-title category-drawer__container-menu-title-clickable category-drawer__container-menu-title-department-level">
                           <div className="category-drawer__container-menu-title-text category-drawer__container-menu-title-text-department-level">How It works</div>
                        </div></Link>
                        <Link to="/about"><div onClick={() => onClick("mobile-menu__content", "ant-drawer ant-drawer-left menu-background")} className="category-drawer__container-menu-title category-drawer__container-menu-title-clickable category-drawer__container-menu-title-department-level">
                           <div className="category-drawer__container-menu-title-text category-drawer__container-menu-title-text-department-level">About us</div>
                        </div></Link>
                        <Link to="/pricing">
                           <div onClick={() => onClick("mobile-menu__content", "ant-drawer ant-drawer-left menu-background")} className="category-drawer__container-menu-title category-drawer__container-menu-title-clickable category-drawer__container-menu-title-department-level">
                              <div className="category-drawer__container-menu-title-text category-drawer__container-menu-title-text-department-level">Premium</div>
                          </div>
                        </Link>
                        <Link to="/reward-center">
                           <div onClick={() => onClick("mobile-menu__content", "ant-drawer ant-drawer-left menu-background")} className="category-drawer__container-menu-title category-drawer__container-menu-title-clickable category-drawer__container-menu-title-department-level">
                              <div className="category-drawer__container-menu-title-text category-drawer__container-menu-title-text-department-level">Reward center</div>
                          </div>
                        </Link>
                        <a href="https://help.reinin.sg/"><div onClick={() => onClick("mobile-menu__content", "ant-drawer ant-drawer-left menu-background")} className="category-drawer__container-menu-title category-drawer__container-menu-title-clickable category-drawer__container-menu-title-department-level">
                           <div className="category-drawer__container-menu-title-text category-drawer__container-menu-title-text-department-level">FAQs</div>
                        </div></a>
                        <Link to="/login">
                       <div onClick={() => onClick("mobile-menu__content", "ant-drawer ant-drawer-left menu-background")} className="category-drawer__container-menu-title category-drawer__container-menu-title-clickable category-drawer__container-menu-title-department-level">
                           <div className="category-drawer__container-menu-title-text category-drawer__container-menu-title-text-department-level">
                              {userToken ? (
                                 "Account"
                             ) : (
                                 "Login"
                             )}
                           </div>
                        </div></Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</div>
);

// Connect redux to component
const mapStateToProps = state => ({
  userToken: state.user.userToken,
});

export default connect(mapStateToProps)(MobileMenu);
