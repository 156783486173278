// Import dependencies
import styled from "styled-components";

import WhatsappIcon from "../assets/icons/whatsapp.svg";
// Import constants
import {
  Constants
} from "../constants/Constants";

/*
    Page layout components
*/
export const PageContainer = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 75px;
  overflow-x: hidden;
  position: relative;
`;

/*
    General box container with border and shadow
*/
export const BoxComponent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 0 0 #dce6ed;
  border: 2px solid #dce6ed;
  ${props => (props.radius ? "border-radius: " + props.radius + "px" : "")};
  ${props => (props.padding ? "padding: " + props.padding : "")};
`;

export const NewBoxComponent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props => (props.bgr ? `background-color: ${props.bgr}` : "background-color: #F7F8FA;")}
  ${props => (props.maxWidth ? `max-width: ${props.maxWidth}px` : "")}
  ${props => (props.radius ? "border-radius: " + props.radius + "px" : "")};
  ${props => (props.padding ? "padding: " + props.padding : "")};
  ${props => (props.margin ? props.margin + " !important" : "")}
`;

/*
    Fluid text components
*/
export const TitleText = styled.h1`
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: calc(${Constants.minTitle}px + (${Constants.maxTitle} - ${
  Constants.minTitle
}) * ((100% - 300px) / (1600 - 300)));
    line-height: 1.5;
    color: ${Constants.titleColour};
    margin: 0 !important;
    letter-spacing: 0;
    text-shadow: 2px 2px 0 ${Constants.shadow};
    ${props =>
  props.subTitle
    ? `font-size: calc(${Constants.minSubTitle}px + (${Constants.maxSubTitle} - ${Constants.minSubTitle}) * ((100% - 300px) / (1600 - 300))); text-shadow: 2px 2px 0 ${Constants.shadow}`
    : ""};
    ${props =>
  props.quote
    ? `font-size: calc(${Constants.minQuoteTitle}px + (${Constants.maxQuoteTitle} - ${Constants.minQuoteTitle}) * ((100% - 300px) / (1600 - 300))); text-shadow: 2px 2px 0 ${Constants.shadow}; line-height: normal; text-shadow: 4px 4px 0 ${Constants.shadow}`
    : ""};
    ${props => props.withoutShadow && 'text-shadow: none;'}
    ${props => props.dynamicFontSize? `font-size: calc(${props.dynamicFontSize[0]}px + (${props.dynamicFontSize[1]} - ${props.dynamicFontSize[0]}) * ((100% - 300px) / (1600 - 300)));`: ""};
    ${props => (props.fontSize ? "font-size: " + props.fontSize + "px" : "")};
    ${props => (props.center ? "text-align: center" : "")};
    ${props => (props.colour ? "color: " + props.colour : "")};
    ${props => (props.margin ? props.margin + " !important" : "")}
    ${props => (props.noShadow ? "text-shadow: 0 0 0" : "")}
    ${props => (props.burgerMenu ? "line-height: 10vh; cursor: pointer; font-size: 28px" : "")}
    ${props => (props.left ? "text-align: left" : "")}
    ${props => props.weight && "font-weight: " + props.weight}
    ${props => props.inline && "display: inline-block"}
    ${props => props.lineHeight && `line-height: ${props.lineHeight}`}
`;
export const ParagraphText = styled.p`
    font-family: 'Inter', sans-serif;
    ${props => (props.bold ? "font-weight: 600" : "font-weight: 400")};
    font-size: calc(${Constants.minParagraphText}px + (${
  Constants.maxParagraphText
} - ${Constants.minParagraphText}) * ((100% - 300px) / (1600 - 300)));
    letter-spacing: 0;
    color: ${Constants.fontColour};
    margin: 0 !important;
    ${props => (props.lineHeight ? `line-height: ${props.lineHeight}` : "line-height: 1.7")};
    ${props =>
  props.medium
    ? `font-size: calc(${Constants.maxParagraphText}px + (${Constants.maxSubTitle} - ${Constants.minSubTitle}) * ((100% - 300px) / (1600 - 300)));`
    : ""};
    ${props => (props.colour ? "color: " + props.colour : "")};
    ${props => (props.fontSize ? "font-size: " + props.fontSize + "px" : "")};
    ${props => props.dynamicFontSize? `font-size: calc(${props.dynamicFontSize[0]}px + (${props.dynamicFontSize[1]} - ${props.dynamicFontSize[0]}) * ((100% - 300px) / (1600 - 300)));`: ""};
    ${props => (props.center ? "text-align: center" : "")};
    ${props => (props.margin ? props.margin + " !important" : "")}
    ${props =>
  props.separator
    ? `font-family: 'Inter', sans-serif; font-weight: 700`
    : ""}
    ${props => props.otherFont ? `font-family: 'Inter', sans-serif;` : ""}
    ${props => (props.size ? "font-size: " + props.size + "px" : "")}
    ${props => (props.decoration ? "text-decoration: " + props.decoration : "")}
    ${props => (props.link ? "cursor: pointer" : "")}
    ${props => (props.padding ? props.padding : "")}
    ${props => (props.fluid ? "flex: 1 1 auto" : "")}
    ${props => (props.userSelect ? `user-select: ${props.userSelect}`: "")}
    ${props => (props.pointer ? "cursor: pointer" : "")};
    ${props => (props.wordBreak ? "word-break: break-word" : "")};
`;
export const NavLink = styled.p`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0;
  color: ${Constants.fontColour};
  margin: 0 !important;
  cursor: pointer;
  ${props => (props.bold ? "font-weight: 700" : "")};
  ${props => (props.margin ? props.margin + " !important" : "")}
  ${props =>
  props.selected
    ? "font-weight: 700; color: " + Constants.mainGreen
    : ""}

    &:hover {
    color: ${Constants.mainGreen};
  }

  -webkit-transition: color 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: color 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
`;

export const Whatsapp = styled.div`
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 70px;
  height: 70px;
  text-align: center;
  z-index: 100;
`;

export const WhatsappLink = styled.a`
  display: block;
`;

export const WhatsappImg = styled(WhatsappIcon)`
  width: 100%;
  height: 100%;
`;
