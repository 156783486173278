// Import dependencies
import React from "react"

export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/

export const generatePassComplexityError = () => {
  return (
    <>
      <span>
        Password should have:
        <br />
      </span>
      <span>
        • At least 8 characters.
        <br />
      </span>
      <span>
        • At least one upper case letter.
        <br />
      </span>
      <span>
        • At least one lower case letter.
        <br />
      </span>
      <span>
        • At least one digit.
        <br />
      </span>
      <span>
        • At least one special character.
        <br />
      </span>
    </>
  )
}
