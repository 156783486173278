// Import dependencies
import React from "react";

// Import components
import { Button } from "antd";
import { Link } from "gatsby";

// Import styles
import "./general-button.css";

/*
    Main button component
*/
const GeneralButton = ({
  title,
  type,
  margin,
  fit,
  link,
  htmlType,
  onClick,
  disabled,
  state,
  center = false,
}) =>
  link ? (
    <Link to={link} style={{ flex: 1 }} onClick={onClick} state={state}>
      <Button
        style={center ? { display: "block", margin: "auto" } : margin}
        type={type}
        block={fit}
        htmlType={htmlType}
        disabled={disabled}
      >
        {title}
      </Button>
    </Link>
  ) : (
    <div style={{ flex: 1 }} onClick={onClick}>
      <Button
        style={center ? { display: "block", margin: "auto" } : margin}
        type={type}
        block={fit}
        htmlType={htmlType}
        disabled={disabled}
      >
        {title}
      </Button>
    </div>
  );

export default GeneralButton;
