// Import dependencies
import React from "react";
import {Button} from "antd";

// Import components
import GeneralButton from "../form/buttons/GeneralButton";
import {ParagraphText} from "../../styled-components/UILibrary";
import CartItem from "./cartItem";
import Hammer from "../../assets/images/hammer.png"

import { navigate, Link } from "gatsby"
import ReininCoin from "../../assets/images/reinin_coin_quote.png";
import CartChallengeIMG from "../../assets/images/cart_challenge.png";

// Import styles
import "./cart.css";
import {Constants} from "../../constants/Constants";

// Import redux
import {connect} from "react-redux";
import GeneralCartItem from "./genCartItem";
import {getCartQuote, getCartCoin} from "../../util/cart";

/*
    Shopping Cart component
*/
const CartComponent = ({cart, generalCart, configs, loggedIn, showCart, styles, popupOpen, togglePopup}) => {
    const quote = getCartQuote(cart, generalCart);
    const quoteCoin = getCartCoin(generalCart);
//    const blockCheckout = quote < parseInt(configs["minimum_checkout"]);
    const totalCount = cart.length + generalCart.length;
    const blockCheckout = totalCount < 1;
//    const buttonText = blockCheckout ? "Checkout ($" + parseInt(configs["minimum_checkout"]) + " minimum)" : "Checkout"
const buttonText = "Checkout";
    return (
      <div class={styles} >
        <div className="cart__background"></div>
        <div className="cart__container">
        <div className="cart_title-container">
            <p className="cart_title">YOUR CART</p>
            <div class="cart__close" onClick={showCart}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.4501 0.565078C18.8651 -0.0199215 17.9201 -0.0199215 17.3351 0.565078L10.0001 7.88508L2.66508 0.550078C2.08008 -0.0349219 1.13508 -0.0349219 0.550078 0.550078C-0.0349219 1.13508 -0.0349219 2.08008 0.550078 2.66508L7.88508 10.0001L0.550078 17.3351C-0.0349219 17.9201 -0.0349219 18.8651 0.550078 19.4501C1.13508 20.0351 2.08008 20.0351 2.66508 19.4501L10.0001 12.1151L17.3351 19.4501C17.9201 20.0351 18.8651 20.0351 19.4501 19.4501C20.0351 18.8651 20.0351 17.9201 19.4501 17.3351L12.1151 10.0001L19.4501 2.66508C20.0201 2.09508 20.0201 1.13508 19.4501 0.565078Z" fill="#828282"/>
                </svg>
            </div>
        </div>
            <div className="cart__scroll">
            <span className="cart_title">We’ll pay you: </span>
            <div className="cart_quotes">
                <span className="cart_quotes_type">Cash:</span>
                <span className="cart_quotes_total">${quote}</span>
            </div>
            <div className="cart_quotes">
                <span className="cart_quotes_type">Reinin Coins:</span>
                <span className="cart_quotes_total">{quoteCoin}</span>
            </div>
            <span className="cart_items_count">Items in cart: {cart.length + generalCart.reduce( function(a, b){

                return a + (b.isBulk ? b.functionalQuantity : b.quantity);
            }, 0)}</span>
            
            <div className={totalCount === 0 || blockCheckout ? "cart_btn cart_btn_disable" : "cart_btn"}
                onClick={() => {
                    
                    if (!blockCheckout) {
                        if (loggedIn) {
                            navigate("/step-1")
                        } else {
                            navigate("/login", {
                                state: {
                                    redirect: "step-1"
                                }
                            })
                        }
                    }
                }}
            >{buttonText}</div>

            <Link to="/galleria/categories"><div className="cart_btn cart_btn_add">Add More</div></Link>

            <div className="cart_challenge_box" style={{backgroundImage: "url('" + CartChallengeIMG + "')"}}>
                <div className="cart_challenge_box_content">
                    <h1>challenge</h1>
                    <p>Get an extra <b>500 Reinin Coins</b> when you checkout with at least 20 items in your cart</p>
                </div>
            </div>

            <div className="quote_coins_info"  onClick={togglePopup}>
                <span>What are Reinin Coins?</span>
                <img src={ReininCoin} />
            </div>
            <span className="cart_title">YOU ARE RECYCLING</span>

            {cart &&
            cart.map((item, i) =>
                item.promo_code_value ? (
                    <GeneralCartItem
                        key={i}
                        margin="margin-bottom: 15px"
                        item={"Promo code"}
                        quote={"$" + item.promo_code_value}
                        index={i}
                    />
                ) : (
                    <CartItem
                        key={i}
                        margin="margin-bottom: 15px"
                        id={item.item_id}
                        brand={item.brand}
                        model={item.model}
                        condition={item.condition}
                        powersOn={item.powersOn}
                        quote={item.type == "member" ? "$" + item.quote : item.quote + " Reinin Coins"}
                        index={i}
                    />
                )
            )}

            {generalCart
                ? generalCart.map((item, i) =>
                    item.promo_code_value ? (
                        <GeneralCartItem
                            key={i}
                            margin="margin-bottom: 15px"
                            item={"Promo code"}
                            quote={"$" + (Math.round(item.promo_code_value * 100) / 100)}
                            index={i}
                            />
                    ) : (
                        <GeneralCartItem
                            key={i}
                            margin="margin-bottom: 15px"
                            id={item.item_id}
                            item={item.item}
                            quantity={"Quantity: " + item.quantity}
                            quote={item.type == "member" || item.isBulk ? "Item price: $" + (Math.round(item.quote * 100) / 100) : "Item price: " + (Math.round(item.quote * 100) / 100) + " Reinin Coins"}
                            total_price={item.type == "member" || item.isBulk ? "Total: $" + (Math.round(item.total_price * 100) / 100) : "Total: " + (Math.round(item.total_price * 100) / 100) + " Reinin Coins"}
                            index={i}
                            />
                    )
                )
                : null}

            
                </div>
        </div>
        </div>
    );
};

// Connect redux to component
const mapStateToProps = state => ({
    cart: state.cart,
    generalCart: state.generalCart,
    configs: state.product.configs,
    loggedIn: state.user.isLoggedIn
});

export default connect(mapStateToProps)(CartComponent);
