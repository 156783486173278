// Import dependencies
import React from "react";

// Import components
import {
  ParagraphText,
  NewBoxComponent,
} from "../../styled-components/UILibrary";

// Import styles
import "./cart-item.css";
import { Constants } from "../../constants/Constants";

// Import redux
import { connect } from "react-redux";
import { removeItemFromCart } from "../../redux/actions/cartAction";
import Close from "../../assets/icons/close.svg";

// Import Utils
import {toTitleCase} from "../../util/cart";
/*
    Cart Item component for Shopping Cart component
*/
const CartItem = ({
  condition,
  powersOn,
  quote,
  dispatch,
  brand = "",
  model = "",
  forReview = false,
  separateModel = false,
  margin,
  id,
  checkoutPage = false,
  reCheckChallenges,
  index
}) => (
  <div className="cart_item__wrapper">
    <div className="cart_item__container">
      <div className="cart_items">
        <div className="cart_item__section" style={forReview ? {width: "60%"} : {}}>
          <p className="cart_item_product_name">
            {/*forReview && !separateModel
              ? brand ? `${brand.toUpperCase()} ${model.toUpperCase()}` : model.toUpperCase()
              : brand ? brand.toUpperCase() : model.toUpperCase()*/}
              {model}
          </p>
          {/*!forReview ||
            (separateModel && (
              <ParagraphText
                wordBreak
                separator
                lineHeight="18px"
                size={12}
                colour={Constants.titleColour}
              >
                {model.toUpperCase()}
              </ParagraphText>
            ))*/}
          <p className="cart_item_info">Condition: {toTitleCase(condition)}</p>
          <p className="cart_item_info">Function: {toTitleCase(powersOn)}</p>
          {checkoutPage &&
            <p className="cart_item_remove"  onClick={() => {dispatch(removeItemFromCart(index)); reCheckChallenges();}} >Remove</p>}
          {(!forReview && !checkoutPage) && (
            <>
              <p className="cart_item_info">Item Price: {toTitleCase(quote)}</p>
              <p className="cart_item_info">Total: {toTitleCase(quote)}</p>
            </>
          )}
        </div>
        {checkoutPage &&
          <>
            <p className="checkout_cart_infos">1</p>
            <p className="checkout_cart_infos">{quote}</p>
          </>
        }
        {forReview && (
          <div className="cart_item__section" style={{width: "20%"}}>
            <ParagraphText
              otherFont
              colour="#000000"
              lineHeight="16px"
              className="cart_item__total"
            >
              {quote.toUpperCase()}
            </ParagraphText>
          </div>
        )}

        {!checkoutPage &&
            <svg className="cart_item_remove_icon" onClick={() => dispatch(removeItemFromCart(index))} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.4501 0.565078C18.8651 -0.0199215 17.9201 -0.0199215 17.3351 0.565078L10.0001 7.88508L2.66508 0.550078C2.08008 -0.0349219 1.13508 -0.0349219 0.550078 0.550078C-0.0349219 1.13508 -0.0349219 2.08008 0.550078 2.66508L7.88508 10.0001L0.550078 17.3351C-0.0349219 17.9201 -0.0349219 18.8651 0.550078 19.4501C1.13508 20.0351 2.08008 20.0351 2.66508 19.4501L10.0001 12.1151L17.3351 19.4501C17.9201 20.0351 18.8651 20.0351 19.4501 19.4501C20.0351 18.8651 20.0351 17.9201 19.4501 17.3351L12.1151 10.0001L19.4501 2.66508C20.0201 2.09508 20.0201 1.13508 19.4501 0.565078Z" fill="#828282"/>
            </svg>
        }
      </div>
    </div>
  </div>
);

// Connect redux to component
export default connect()(CartItem);
