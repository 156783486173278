// Import dependencies
import React from "react"

// Import components
import { TitleText, ParagraphText } from "../styled-components/UILibrary"

/*
    Contains data for Legals & Disclaimers modal
*/

export const TermsAndConditions = () => (
  <>
    <TitleText subTitle>Terms and Conditions ("Terms")</TitleText>
    <ParagraphText margin="margin-top: 30px">
      Last updated on: 29 December 2019
      <br />
      <br />
      This Service Agreement (“Agreement”) sets out the terms by which Reinin
      (Singapore) Pte. Ltd. (“we”, “us”, or “our”), through our Website, may
      provide a Service where you (the “User”) offer to sell your used
      Device(s). You must accept this Agreement before using our Website and/or
      engaging our Services.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      GOVERNING LAW
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      This Agreement and your use of this Website shall be governed in all
      respects by the laws of Singapore.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      SERVICE TERMS
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      By accessing and using the Website, you represent and warrant that you are
      either at least eighteen (18) years of age and that you have the right,
      authority and capacity to agree to this Agreement and to abide by all of
      its terms and conditions; or that your parent or legal guardian who is at
      least eighteen (18) years of age has agreed to be bound with you by this
      Agreement.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Identification:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      Disclosure of some identification information is required in order to use
      our Services. All information collected is treated as described in our
      Data Protection Notice. You agree to provide accurate, current and
      complete information about yourself. You also agree that we require your
      identification information to be accurate, current, and complete, in order
      to provide your our Service. We reserve the right to terminate your
      account for inaccurate, deceptive, or incomplete identification
      information.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      User Accounts:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      You agree that you are solely responsible for any and all activities that
      are conducted using your account. You agree that you will not transfer,
      resell, or allow any other third party to use your account to access the
      Website. If you have reason to believe that your account has become
      compromised, you agree to immediately notify us of such event via email at{" "}
      <a href="mailto:support@reinin.sg">support@reinin.sg</a>.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Right to Refuse Service:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      Our Services are not available to temporarily or indefinitely suspended
      Users. We reserve the right, at our sole discretion, to terminate
      unconfirmed or inactive accounts. We also reserve the right to refuse use
      of the Website or Service to anyone, for any reason, at any time, at our
      sole discretion. You agree to use our Services and Website in a manner
      that conforms to any and all applicable international and local laws and
      regulations.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      How Our Service Works:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      You visit the Website and search for the Device(s) that you intend to
      sell. Next, you fill out the Device sale form which asks questions about
      the Device and its condition. Upon receipt of that information, the
      Website will display a price quote indicating the amount of money that we
      will pay for the Device you have described in the Device sale form. The
      price quote is valid for thirty (30) calendar days from the date of
      acceptance by the User.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Accuracy of Information:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      We make all efforts to ensure that the information presented on the
      Website or in other communications including, but not limited to, price
      quotes is accurate. In the event of any typographical errors, technical
      inaccuracies, pricing errors or omissions, we reserve the right to correct
      the error. If we correct an error that will have a material effect on our
      price quote to you, you will have two (2) options: (1) to continue with
      the transaction based on the corrected information; or (2) to cancel your
      transaction, in which case we will promptly return your Device to you.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Accepting the Offer:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      If you choose to accept the offer to buy at the quoted price, you then
      create an account and complete the checkout process.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Device Eligibility:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      We determine, at our sole discretion, which Devices are eligible for
      purchase by or through our Services and for which eligible Devices we will
      provide an offer. We may terminate the eligibility of certain Device at
      any time and without advance notice, but such termination will not affect
      any Device(s) for which you have already accepted an offer in the past
      thirty (30) days.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Collection of Devices:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      You are responsible for preparing and protecting your Device(s) for the
      collection process. Collections are fulfilled either by us or by
      third-party logistics service providers we have partnered with for the
      purpose of collecting your Device(s) on our behalf.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Risk of Loss & Passing of Title:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      If you are a free User, then you assume all risk of loss or damage in
      transit until the Device is received by us at our processing center. If
      you are a paying Member, then your Device shall be insured during transit.
      Title to any and all Devices shall not pass to us until they are received,
      inspected, and accepted at our processing center. If any Device is
      rejected, due to damage or other issues that were not disclosed in your
      Device sale form, then title to that Device will remain with you and the
      Device will be returned to you.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Risk of Loss (detail):
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      We are responsible for the risk-of-loss when we open the package
      containing your Device, but the responsibility ceases in the event that we
      return your Device to you for any reason. For avoidance of doubt, in such
      event, risk-of-loss will be transferred to you once we dispatch the Device
      back to you, and you will bear the risk-of-loss during return transit.
      Devices will not be returned with signature confirmation or with
      insurance.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Passing of Title (detail):
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      Title of the Device(s) passes to us upon our receipt and acceptance of the
      Device for the original quote or the revised offer, as applicable. If
      there is any Return Circumstance, title shall not pass to us and shall
      remain with you.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Inspection of the Device:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      Your Device must be received by us within thirty (30) days after you
      complete a checkout on our site and accept the original offer provided by
      us. You should take sensible measures when packing your Device(s) in order
      to avoid possible damage to or loss during transit. We will inspect all
      Devices that are received. Once we receive the Device, our team inspects
      it to make sure it arrives in the condition stated in your Device sale
      form. We then notify you once your product has been inspected. If it
      arrives in the condition stated, you will receive an email confirming this
      and we will issue payment via your selected payment method in the amount
      of the value quoted.
      <br />
      <br />
      If the Device does not arrive in the condition stated in your Device sale
      form, we will inform you of this via email and either cancel our offer and
      ship the Device back to you at the address of record on your account, or
      make a lower offer based on the condition it was received in. Failure to
      include critical components such as chargers, AV cords, remote
      controllers, etc. will result in a recalculation of the quote originally
      provided to you.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Return Circumstance:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      In the event that we send you a revised offer for your Device after it has
      been received and inspected, as described above, you shall have the option
      to either accept or reject the revised offer. If you accept the revised
      offer, you will be paid in accordance with these Service Terms. If you
      reject the revised offer, we will return the Device to you at the address
      of record on your account. Prohibited Devices as outlined below will not
      be eligible for return and any device reported to be either stolen or
      counterfeit will not be returned.
      <br />
      <br />
      You will have the opportunity to accept or reject our revised offer for up
      to five (5) days (the “Revised Offer Period”). We will also remind you of
      the revised offer one more time during the Revised Offer Period. If you do
      not respond within five (5) days, we will infer your acceptance of our
      revised offer and automatically process the Device at the price quote
      adjusted by us to reflect the appropriate condition, and you will be paid
      the revised offer price in the normal course and in accordance with these
      Service Terms. For the avoidance of any and all doubt, your acceptance of
      the original offer and/or any revised offer is final and you will not be
      able to change your mind later about accepting such offer.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Releasing Rights for a Device:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      Once you send us your Device, we cannot and will not guarantee that we
      will be able to honor any request for return of the Device and/or any data
      or information contained in the Device. Of course, if we reject the Device
      or you do not accept any revised offer for the Device, then your Device
      will be returned to you.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Our Rating System for Device Condition Upon Receipt:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      We use three (3) basic Device conditions to grade Devices, ranging from
      “Not good” with physical or functional issues to “Looks new”, which is in
      many respects equivalent to buying a new device. Conditions can vary
      widely between categories and types of Devices. Broadly speaking, our
      Device definitions are as follows:
      <br />
      <br />
      Not good: The Device does not function properly or has significant
      physical damage, including cracks, broken displays, missing parts, or
      personalization etc. A bad electronic serial number is also considered to
      be in “Not good” condition.
      <br />
      <br />
      Clearly used: The Device functions as it should but there is evidence of
      normal use, such as scratches and dents. There are no missing components,
      no missing peripherals, or cracks. The Device must have a clean electronic
      serial number.
      <br />
      <br />
      Looks new: The Device functions as it should and there is no clear sign of
      wear. There are no cracks, scratches, scuffs, missing components, or
      missing peripherals. The Device must have a clean electronic serial
      number.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Prohibited Devices:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      Certain Devices are prohibited. By using the Service, you represent that
      your Device(s) are not prohibited. You agree to indemnify the Covered
      Parties (as defined herein) from any liability resulting from your
      submission to us of prohibited Device(s). Additionally, Prohibited Devices
      will not be returned to the User. Prohibited Devices include, but are not
      necessarily limited to:
      <br />
      <br />
      Illegal, counterfeit, or stolen Devices;
      <br />
      <br />
      Devices that infringe on any third party’s intellectual property
      (copyright, trademark, trade secret, patent) or publicity or privacy
      rights;
      <br />
      <br />
      Devices that violate any law, statute, ordinance or regulation (including,
      but not limited to, those governing import/export control, consumer
      protection, unfair competition, anti-discrimination or false advertising);
      <br />
      <br />
      Devices to which you do not own the right, title and interest to the
      Device or to which you are not an authorized representative of the owner;
      <br />
      <br />
      Devices that contain materials or substances that have been identified as
      hazardous to consumers and therefore are subject to a recall or Devices
      which otherwise fail to comply with any rules and regulations; or
      <br />
      <br />
      Devices that are obscene or which were used during the course of
      perpetrating any and all other criminal offences.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Data Removal:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      It is highly recommended that you remove any and all data from your Device
      before sending the Device to us. We work very hard to maintain the highest
      standards of data security, and guarantee that if upon accepting the
      transfer of title to us for a Device we find that the Device contains any
      User data, then that data will be erased by us before any further action
      is taken. We are in no way responsible for data contained on Devices sent
      to us for which we have not accepted the transfer of title to us.
      <br />
      <br />
      If you are a paying Member who has sold us a phone, laptop, desktop, or
      tablet computer, then you are entitled to a data erasure certificate that
      confirms any and all data has been erased from the Device according to the
      standards of the data erasure software we use. We have carefully selected
      reliable data erasure software to use, but we cannot and will not
      guarantee the integrity of such software, and are not responsible for any
      defects in the software which have not been disclosed to us by the
      software provider.
      <br />
      <br />
      By sending your Device(s) to us, you agree to release us from any
      potential claim or liability as to the Device(s) and as to the data stored
      on the Device(s). We are not responsible for any loss suffered by you as a
      result of data that is not erased from your Device and which is obtained
      by a third party.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Promotions:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      From time to time, we may offer promotional programs, at our sole
      discretion. By participating in those promotions, you agree to be subject
      to the terms of use governing that specific promotion in addition to the
      terms included in this Agreement. Promotions applicable to payments are
      applied as an increment to the final value of a customer's Device, but
      they are not considered a change in that final value. Promotional programs
      have explicitly defined terms, including but not limited to, expiration,
      usage per person and per transaction, and other limitations and
      restrictions. Unless otherwise stated, promotions must be applied by you
      before checkout. For the avoidance of doubt, once checkout is completed,
      promotions cannot be applied to the same transaction. We reserve the
      right, at our sole discretion, to cancel or refuse promotions.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Limited, Non-Exclusive License:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      You are granted a non-exclusive, non-transferable, limited license to
      access and use our Services and Website in accordance with the Agreement
      (“License”). This License may be revoked at any time at our sole
      discretion. The License permits you to access and use our Services and
      Website for your own personal use. No part of the Website and/or Services
      may be reproduced in any form or incorporated into any information
      retrieval system, electronic or mechanical. The License specifically
      prohibits you from copying, emulating, renting, leasing, decompiling,
      scraping, transferring, disassembling, cloning, attempting to discover the
      source code for or reverse engineering the Website, Services or any
      portion thereof.
      <br />
      <br />
      In addition, your license specifically prohibits you from using any
      software, device or routine to interfere with the normal or proper working
      order of the Website or Services. You may not engage in any action which
      creates an unreasonably large load on our infrastructure. Unauthorized
      access to the Website and/or Services breaches this Agreement and is a
      violation of applicable law.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Intellectual Property Rights:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      All of the content, organization, graphics, design, compilation, magnetic
      translation, digital conversion, software, services, computer coding and
      other matters related to the Website and associated Services, are the sole
      property of Reinin (Singapore) Pte. Ltd., except where specifically
      indicated. As such, they are protected to the maximum extent permitted by
      copyright laws, trademark laws, other proprietary laws (including, but not
      limited to, intellectual property and trade secret laws) and international
      treaties.
      <br />
      <br />
      Any content displayed on the Website is protected by copyright laws and
      international treaties as an original work, a compilation and a collective
      work. Any reproduction, modification, creation of derivative works from or
      distribution of the Website or the collective work and/or copying or in
      any way reproducing the Website or any portion thereof to any fixed medium
      for reproduction or redistribution is prohibited without our express
      written consent. Without limiting any of the specific rights or
      prohibitions herein, you agree not to reproduce, duplicate, copy, sell or
      exploit for any commercial purpose our Services or Website or any aspect
      or portion thereof.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Privacy:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      Use of our Website and/or Services is governed by our Privacy Policy,
      which can be found at:{" "}
      <a href="www.reinin.sg">www.reinin.sg</a>. Our Privacy
      Policy may change from time to time and without notice. We reserve the
      right to use your personally identifiable information in any manner
      consistent with our Privacy Policy. Use of our Services and/or Website
      means that you agree to subject your personally identifiable information
      to our Privacy Policy.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Third-party Websites:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      The Website and/or Services may provide hyperlinks, banner advertisements,
      pop-ups and/or other online methods to redirect you to other third party
      Internet websites and/or resources. Because we have no control over such
      websites and/or resources, you hereby acknowledge and agree that we are
      not responsible for the availability of such third party websites and/or
      resources. Furthermore, we do not endorse, and are not responsible or
      liable for any content, advertising, services, products and/or other
      materials at, or available through, such third party websites or
      resources, or for any damages and/or losses incurred through such websites
      or other resources.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Copyright & Trademark Claims:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      We respect the intellectual property rights of others, and we ask our
      Users to do the same. If you believe that your work has been copied in a
      way that constitutes copyright/trademark infringement, or your
      intellectual property rights have been otherwise violated, please provide
      our intellectual property agent with the following information:
      <br />
      <br />
      A physical or electronic signature of a person authorized to act on behalf
      of the owner of an exclusive right that is allegedly infringed.
      <br />
      <br />
      Identification of the copyrighted work claimed to have been infringed or,
      in the case of multiple copyrighted works, a representative list of such
      works or of the trademark that is being allegedly infringed.
      <br />
      <br />
      Identification of the material that is claimed to be infringing or to be
      the subject of infringing activity and that is to be removed or access to
      which is to be disabled, and information reasonably sufficient to permit
      us to locate the material.
      <br />
      <br />
      Information reasonably sufficient to permit us to contact you, such as an
      address, telephone number and, if available, an email address at which you
      may be contacted.
      <br />
      <br />
      A statement that you have a good faith belief that use of the material in
      the manner complained of is not authorized by the intellectual property’s
      owner, its agent or under applicable laws.
      <br />
      <br />
      A statement that the information in the notification is accurate and,
      under penalty of perjury, that you are authorized to act on behalf of the
      owner of an exclusive right that is allegedly infringed.
      <br />
      <br />
      Our agent for notice of claims of intellectual property rights
      infringement can be reached here:
      <br />
      <br />
      By mail:
      <br />
      Reinin (Singapore) Pte. Ltd.
      <br />
      1 Scotts Road #24-10, Shaw Centre, Singapore 228208
      <br />
      <br />
      By email:
      <br />
      <a href="mailto:support@reinin.sg">support@reinin.sg</a>
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Indemnification:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      You agree to indemnify, defend and hold us, and each of our respective
      officers, executives, employees, owners, and authorized agents (“Covered
      Parties”), as being harmless from any and all loss, liability, claims
      and/or demands (including reasonable attorneys' fees, costs and settlement
      amounts) made by any third party due to, or arising out of, your use of
      the Website, our Services and/or arising from your breach of this
      Agreement.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Warranties, and Limitation of Liability:{" "}
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      You expressly agree that access and/or use of the website and/or services
      is at your sole risk. The website and services are provided on an "as is"
      and "as available" basis, without any express or implied warranty of any
      kind, including but not limited to, warranties of merchantability,
      non-infringement of intellectual property or fitness for a particular
      purpose. The website and/or services may contain bugs, errors, problems or
      other limitations. Reinin and the covered parties have no liability
      whatsoever for your use of, or inability to use, the website and/or
      services.
      <br />
      <br />
      To the fullest extent permitted by applicable law, Reinin and the
      covered parties are not liable to you or any third party for any indirect,
      special, incidental or consequential damages (including damages for loss
      of business, loss of profits, litigation, or the like), whether based on
      breach of contract, breach of warranty, tort (including negligence),
      product liability or otherwise, even if advised of the possibility of such
      damages. The negation of damages set forth above is a fundamental element
      of the basis of the bargain between Reinin and you. The website and
      services would not be provided without such limitations. No advice or
      information, whether oral or written, obtained by you from Reinin
      through the website and/or services shall create any warranty,
      representation or guarantee not expressly stated in this agreement.
      <br />
      <br />
      Reinin is not responsible to you and/or any third party, without
      limitation, for: 1) any error, omission, interruption, deletion, defect,
      delay in operation or transmission, communications line failure, theft or
      destruction or unauthorized access to or alteration of, User and/or Member
      communications; 2) any potentially offensive, incorrect or inaccurate
      content posted on the website or provided in connection with the services,
      whether caused by Reinin, Users or by any of the equipment or
      programming associated with, or utilized in, the services; 3) any computer
      virus or other malicious, destructive or corrupting code, agent, program
      or macros; or 4) any infringement of another's rights, including
      intellectual property rights.
      <br />
      <br />
      If you are dissatisfied with the website and/or services or with this
      agreement, your sole and exclusive remedy is to discontinue use of the
      website and services
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Severability:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      Should any part of this Agreement be held invalid or unenforceable, that
      portion shall be construed consistent with applicable law and the
      remaining portions shall remain in full force and effect. To the extent
      that anything in or associated with the Website or Services is in conflict
      or inconsistent with this Agreement, this Agreement shall take precedence.
      Our failure to enforce any provision of this Agreement shall not be deemed
      a waiver of such provision nor of the right to enforce such provision.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Force Majeure:{" "}
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      We are not responsible for any loss, damages, costs, claims or expenses
      which you may incur as a result of our delay in or failure to perform our
      obligations where such delay or failure is due to causes beyond our
      control. Causes beyond our control include, but are not limited to: fire,
      flood, earthquake, accident, civil disturbances, war, rationing,
      embargoes, strikes, labor problems, delays in transportation, inability to
      secure necessary materials, delay or failure of performance of any
      supplier or subcontractor, acts of God, and acts of Government.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Legal Warning:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      Any attempt by any individual, whether a User or otherwise, to damage,
      destroy, tamper with, vandalize and/or otherwise interfere with the
      operation of the Website and/or Services, is a violation of criminal and
      civil law and we will diligently pursue all remedies in this regard
      against any offending individual or entity to the fullest extent
      permissible by law.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      Modifications:
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      This Agreement is subject to change at any time, with or without notice.
      All changes are effective upon their posting on the Website. You agree
      that it is your responsibility to check the Agreement for updates. Every
      time you submit a Device for sale to us, you reaffirm your acceptance of
      this Agreement as in effect at the time of such use. You are responsible
      for reviewing this Agreement each time you use our Website. If you object
      to any of the terms and conditions in the Agreement, if you become
      dissatisfied with our Services or any subsequent modifications to the
      Agreement, your sole recourse is to immediately discontinue the use of our
      Services. Terms and Conditions modifying this Agreement are effective
      immediately upon posting.
    </ParagraphText>
  </>
)

export const PrivacyPolicy = () => (
  <>
    <TitleText subTitle>Data Protection Notice</TitleText>
    <ParagraphText margin="margin-top: 30px">
      Last updated on: 29 December 2019
      <br />
      <br />
      This Data Protection Notice (“Notice”) sets out the basis which Reinin
      (Singapore) Pte. Ltd. (“we”, “us”, or “our”) may collect, use, disclose or
      otherwise process personal data of our customers in accordance with the
      Personal Data Protection Act (“PDPA”).
      <br />
      <br />
      This Notice applies to personal data in our Reininn or under our
      control, including personal data in the Reininn of organisations which
      we have engaged to collect, use, disclose or process personal data for our
      purposes.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      GOVERNING LAW
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      This Notice and your use of this website shall be governed in all respects
      by the laws of Singapore.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      PERSONAL DATA
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      1. As used in this Notice:
      <br />
      <br />
      “Customer” means an individual who (a) has contacted us through any means
      to find out more about any goods or services we provide, or (b) may, or
      has, entered into a contract with us for the supply of any goods or
      services by us; and
      <br />
      <br />
      “Member” means an individual who has paid us the annual membership fee in
      exchange for enhanced service benefits; and
      <br />
      <br />
      “Personal data” means data, whether true or not, about a customer who can
      be identified: (a) from that data; or (b) from that data and other
      information to which we have or are likely to have access.
      <br />
      <br />
      2. Depending on the nature of your interaction with us, some examples of
      personal data which we may collect from you include:
      <br />
      <br />
      • Full name
      <br />
      • Address
      <br />
      • Email address
      <br />
      • Telephone number
      <br />
      <br />
      In addition to the above, for those who wish to subscribe to our
      membership program, we also request for the following data, which is
      securely handled by our payment service provider:
      <br />
      <br />
      • Credit card number
      <br />
      • Debit card number
      <br />
      <br />
      Our payment service provider is Stripe Inc., and the data they collect is
      handled according to the Stripe Inc. global privacy policy
      (https://stripe.com/en-sg/privacy).
      <br />
      <br />
      3. Other terms used in this Notice shall have the meanings given to them
      in the PDPA (where the context so permits).
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      COLLECTION, USE AND DISCLOSURE OF PERSONAL DATA
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      4. We generally do not collect your personal data unless (a) it is
      provided to us voluntarily by you directly or via a third party who has
      been duly authorised by you to disclose your personal data to us (your
      “authorised representative”) after (i) you (or your authorised
      representative) have been notified of the purposes for which the data is
      collected, and (ii) you (or your authorised representative) have provided
      written consent to the collection and usage of your personal data for
      those purposes, or (b) collection and use of personal data without consent
      is permitted or required by the PDPA or other laws. We shall seek your
      consent before collecting any additional personal data and before using
      your personal data for a purpose which has not been notified to you
      (except where permitted or authorised by law).
      <br />
      <br />
      5. We may collect and use your personal data for any or all of the
      following purposes:
      <br />
      <br />
      (a) performing obligations in the course of or in connection with our
      provision of the goods and/or services requested by you;
      <br />
      <br />
      (b) verifying your identity;
      <br />
      <br />
      (c) responding to, handling, and processing queries, requests,
      applications, complaints, and feedback from you;
      <br />
      <br />
      (d) managing your relationship with us;
      <br />
      <br />
      (e) processing payment or credit transactions;
      <br />
      <br />
      (f) sending your marketing information about our goods or services
      including notifying you of our marketing events, initiatives and
      promotions, lucky draws, membership and rewards schemes and other
      promotions;
      <br />
      <br />
      (g) complying with any applicable laws, regulations, codes of practice,
      guidelines, or rules, or to assist in law enforcement and investigations
      conducted by any governmental and/or regulatory authority;
      <br />
      <br />
      (h) any other purposes for which you have provided the information;
      <br />
      <br />
      (i) transmitting to any unaffiliated third parties including our third
      party service providers and agents, and relevant governmental and/or
      regulatory authorities, whether in Singapore or abroad, for the
      aforementioned purposes; and
      <br />
      <br />
      (j) any other incidental business purposes related to or in connection
      with the above.
      <br />
      <br />
      6. We may disclose your personal data:
      <br />
      <br />
      (a) where such disclosure is required for performing obligations in the
      course of or in connection with our provision of the goods or services
      requested by you; or
      <br />
      (b) to third party service providers, agents and other organisations we
      have engaged to perform any of the functions listed in clause 5 above for
      us.
      <br />
      <br />
      7. The purposes listed in the above clauses may continue to apply even in
      situations where your relationship with us (for example, pursuant to a
      contract) has been terminated or altered in any way, for a reasonable
      period thereafter (including, where applicable, a period to enable us to
      enforce our rights under any contract with you).
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      WITHDRAWING YOUR CONSENT
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      8. The consent that you provide for the collection, use and disclosure of
      your personal data will remain valid until such time it is being withdrawn
      by you in writing. You may withdraw consent and request us to stop using
      and/or disclosing your personal data for any or all of the purposes listed
      above by submitting your request in writing or via email to our Data
      Protection Officer at the contact details provided below.
      <br />
      <br />
      9. Upon receipt of your written request to withdraw your consent, we may
      require reasonable time (depending on the complexity of the request and
      its impact on our relationship with you) for your request to be processed
      and for us to notify you of the consequences of us acceding to the same,
      including any legal consequences which may affect your rights and
      liabilities to us. In general, we shall seek to process your request
      within ten (10) business days of receiving it.
      <br />
      <br />
      10. Whilst we respect your decision to withdraw your consent, please note
      that depending on the nature and scope of your request, we may not be in a
      position to continue providing our goods or services to you and we shall,
      in such circumstances, notify you before completing the processing of your
      request. Should you decide to cancel your withdrawal of consent, please
      inform us in writing in the manner described in clause 8 above.
      <br />
      <br />
      11. Please note that withdrawing consent does not affect our right to
      continue to collect, use and disclose personal data where such collection,
      use and disclose without consent is permitted or required under applicable
      laws.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      ACCESS TO AND CORRECTION OF PERSONAL DATA
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      12. If you wish to make (a) an access request for access to a copy of the
      personal data which we hold about you or information about the ways in
      which we use or disclose your personal data, or (b) a correction request
      to correct or update any of your personal data which we hold about you,
      you may submit your request in writing or via email to our Data Protection
      Officer at the contact details provided below.
      <br />
      <br />
      13. Please note that a reasonable fee may be charged for an access
      request. If so, we will inform you of the fee before processing your
      request.
      <br />
      <br />
      14. We will respond to your request as soon as reasonably possible. Should
      we not be able to respond to your request within thirty (30) days after
      receiving your request, we will inform you in writing within thirty (30)
      days of the time by which we will be able to respond to your request. If
      we are unable to provide you with any personal data or to make a
      correction requested by you, we shall generally inform you of the reasons
      why we are unable to do so (except where we are not required to do so
      under the PDPA).
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      PROTECTION OF PERSONAL DATA
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      15. We implement stringent measures to safeguard your personal data from
      unauthorised access, collection, use, disclosure, copying,
      <br />
      <br />
      16. You should be aware, however, that no method of transmission over the
      Internet or method of electronic storage is completely secure. While
      security cannot be guaranteed, we strive to protect the security of your
      information and are constantly reviewing and enhancing our information
      security measures.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      ACCURACY OF PERSONAL DATA
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      17. We generally rely on personal data provided by you (or your authorised
      representative). In order to ensure that your personal data is current,
      complete, and accurate, please update us if there are changes to your
      personal data by modifying your data inside your User account, or by
      informing our Data Protection Officer in writing or via email at the
      contact details provided below.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      RETENTION OF PERSONAL DATA
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      18. We may retain your personal data for as long as it is necessary to
      fulfil the purpose for which it was collected, or as required or permitted
      by applicable laws. Modification, disposal or similar risks, we have
      introduced appropriate administrative, physical, and technical measures
      such as up-to-date antivirus protection, encryption, and the use of
      privacy filters to secure all storage and transmission of personal data by
      us, and disclosing personal data both internally and to our authorised
      third party service providers and agents only on a need-to-know basis.
      <br />
      <br />
      19. We will cease to retain your personal data, or remove the means by
      which the data can be associated with you, as soon as it is reasonable to
      assume that such retention no longer serves the purpose for which the
      personal data was collected, and is no longer necessary for legal or
      business purposes.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      TRANSFERS OF PERSONAL DATA OUTSIDE OF SINGAPORE
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      20. We generally do not transfer your personal data to countries outside
      of Singapore. However, if we do so, we will take steps to ensure that your
      personal data continues to receive a standard of protection that is at
      least comparable to that provided under the PDPA.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      DATA PROTECTION OFFICER
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      By mail:
      <br />
      Reinin (Singapore) Pte. Ltd.
      <br />
      1 Scotts Road #24-10, Shaw Centre, Singapore 228208
      <br />
      <br />
      By email:
      <br />
      <a href="mailto:support@reinin.sg">support@reinin.sg</a>
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      EFFECT OF NOTICE AND CHANGES TO NOTICE
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      22. This Notice applies in conjunction with any other notices, contractual
      clauses, and consent clauses that apply in relation to the collection,
      use, and disclosure of your personal data by us.
      <br />
      <br />
      23. We may revise this Notice from time to time without any prior notice.
      You may determine if any such revision has taken place by referring to the
      date on which this Notice was last updated. Your continued use of our
      services constitutes your acknowledgement and acceptance of such changes.
    </ParagraphText>
  </>
)

export const Cookies = () => (
  <>
    <TitleText subTitle>Cookie Policy</TitleText>
    <ParagraphText margin="margin-top: 30px">
      This is the Cookie Policy for Reinin Singapore Pte. Ltd., accessible
      from
      <a href="http://www.reinin.sg">http://reinin.sg</a>.
    </ParagraphText>
    <TitleText subTitle margin="margin-top: 50px">
      What Are Cookies
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      As is common practice with almost all professional websites this site uses
      cookies, which are tiny files that are downloaded to your computer, to
      improve your experience. This page describes what information they gather,
      how we use it and why we sometimes need to store these cookies. We will
      also share how you can prevent these cookies from being stored however
      this may downgrade or 'break' certain elements of the sites functionality.
      <br />
      <br />
      For more general information on cookies see the Wikipedia article on HTTP
      Cookies
    </ParagraphText>
    <TitleText subTitle margin="margin-top: 50px">
      How We Use Cookies
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      We use cookies for a variety of reasons detailed below. Unfortunately in
      most cases there are no industry standard options for disabling cookies
      without completely disabling the functionality and features they add to
      this site. It is recommended that you leave on all cookies if you are not
      sure whether you need them or not in case they are used to provide a
      service that you use.
    </ParagraphText>
    <TitleText subTitle margin="margin-top: 50px">
      Disabling Cookies
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      You can prevent the setting of cookies by adjusting the settings on your
      browser (see your browser Help for how to do this). Be aware that
      disabling cookies will affect the functionality of this and many other
      websites that you visit. Disabling cookies will usually result in also
      disabling certain functionality and features of this site. Therefore it is
      recommended that you do not disable cookies.
    </ParagraphText>
    <TitleText subTitle margin="margin-top: 50px">
      The Cookies We Set
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      Account related cookies
      <br />
      If you create an account with us then we will use cookies for the
      management of the signup process and general administration. These cookies
      will usually be deleted when you log out however in some cases they may
      remain afterwards to remember your site preferences when logged out.
      <br />
      <br />
      Login related cookies
      <br />
      We use cookies when you are logged in so that we can remember this fact.
      This prevents you from having to log in every single time you visit a new
      page. These cookies are typically removed or cleared when you log out to
      ensure that you can only access restricted features and areas when logged
      in.
      <br />
      <br />
      Email newsletters related cookies
      <br />
      This site offers newsletter or email subscription services and cookies may
      be used to remember if you are already registered and whether to show
      certain notifications which might only be valid to subscribed/unsubscribed
      users.
      <br />
      <br />
      Orders processing related cookies
      <br />
      This site offers e-commerce or payment facilities and some cookies are
      essential to ensure that your order is remembered between pages so that we
      can process it properly.
      <br />
      <br />
      Forms related cookies
      <br />
      When you submit data to through a form such as those found on contact
      pages or comment forms cookies may be set to remember your user details
      for future correspondence.
    </ParagraphText>
    <TitleText subTitle margin="margin-top: 50px">
      Third Party Cookies
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      In some special cases we also use cookies provided by trusted third
      parties. The following section details which third party cookies you might
      encounter through this site.
      <br />
      • This site uses Google Analytics which is one of the most widespread and
      trusted analytics solution on the web for helping us to understand how you
      use the site and ways that we can improve your experience. These cookies
      may track things such as how long you spend on the site and the pages that
      you visit so we can continue to produce engaging content. For more
      information on Google Analytics cookies, see the official Google Analytics
      page.
      <br />
      <br />
      • From time to time we test new features and make subtle changes to the
      way that the site is delivered. When we are still testing new features
      these cookies may be used to ensure that you receive a consistent
      experience whilst on the site whilst ensuring we understand which
      optimisations our users appreciate the most.
      <br />
      • As we sell products it's important for us to understand statistics about
      how many of the visitors to our site actually make a purchase and as such
      this is the kind of data that these cookies will track. This is important
      to you as it means that we can accurately make business predictions that
      allow us to monitor our advertising and product costs to ensure the best
      possible price.
      <br />
      • The Google AdSense service we use to serve advertising uses a
      DoubleClick cookie to serve more relevant ads across the web and limit the
      number of times that a given ad is shown to you.
      <br />
      <br />
      For more information on Google AdSense see the official Google AdSense
      privacy FAQ.
      <br />
      • We use adverts to offset the costs of running this site and provide
      funding for further development. The behavioural advertising cookies used
      by this site are designed to ensure that we provide you with the most
      relevant adverts where possible by anonymously tracking your interests and
      presenting similar things that may be of interest.
      <br />
      • Several partners advertise on our behalf and affiliate tracking cookies
      simply allow us to see if our customers have come to the site through one
      of our partner sites so that we can credit them appropriately and where
      applicable allow our affiliate partners to provide any bonus that they may
      provide you for making a purchase.
      <br />• We also use social media buttons and/or plugins on this site that
      allow you to connect with your social network in various ways. For these
      to work the following social media sites including Facebook, Google Plus,
      Instagram, and LinkedIn, will set cookies through our site which may be
      used to enhance your profile on their site or contribute to the data they
      hold for various purposes outlined in their respective privacy policies.
    </ParagraphText>

    <TitleText subTitle margin="margin-top: 50px">
      More Information
    </TitleText>
    <ParagraphText margin="margin-top: 30px">
      Hopefully that has clarified things for you and as was previously
      mentioned if there is something that you aren't sure whether you need or
      not it's usually safer to leave cookies enabled in case it does interact
      with one of the features you use on our site. However, if you are still
      looking for more information then you can contact us at:
      <br />
      <br />• Email:{" "}
      <a href="mailto: support@reinin.sg">support@reinin.sg</a>
      <br />
    </ParagraphText>
  </>
)
