// Import dependencies
import React from "react";
import PropTypes from "prop-types";

// Import components
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import Loader from "../components/loader/loader";
import LogoAdminBackground from "../assets/images/logo_admin_background.png";
import { Link } from "gatsby"

// Import styles
import "./layout.css";
import register from "../components/account/register";

/* This is a layout component for Possessio pages with Loader */
const Layout = ({
  children,
  noFooter,
  noHeader,
  isHome,
  stepPage,
  step4Page,
  checkoutWhite,
  noWhatsapp,
  adminPage,
  center,
  awaitLoaderData = false,
  hasLoaderAwaiter = false,
  loginFooter, 
  registerFooter,
  isShop
}) => (
  <>
    <Loader hasAwaiter={hasLoaderAwaiter} awaitData={awaitLoaderData} />
    <div className= {adminPage ? "layout__container layout__container_admin" : checkoutWhite && stepPage ? "layout__container layout_step layout_white" : stepPage ? step4Page ? "layout__container layout_step layout_step4" : "layout__container layout_step" : "layout__container"} style={isHome ? {background: "transparent"} : {}}>
      {!noHeader ? <Header HomeIcons={isHome} shopPage={isShop}/> : null}
      <main style={adminPage ? {backgroundImage: "radial-gradient(100% 256% at 100% 0%, #FFFFFF 0%, rgba(255, 249, 229, 0.3) 61.42%, rgba(10, 174, 103, 0.15) 100%),url('" + LogoAdminBackground + "')"}
      : center ? {height: "100%", display: "flex", flexDirection: "column"} : {}}>{children}</main>

      {registerFooter && 
        <footer className="footer_container footer_account">
          <span className="account_bottom_link">Already have an account?<Link to="/login">Login</Link></span>
        </footer>
      }
      {loginFooter && 
        <footer className="footer_container footer_account">
          <span className="account_bottom_link">Don’t have an account?<Link to="/register">Sign up for free</Link></span>
        </footer>
      }

      {!noFooter && !loginFooter && !registerFooter ? <Footer /> : null}

    </div>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
