// Import dependencies
import React from "react"
// Import components
import {Form, Input} from "antd"
// Import styles
import "./input-field.css"

import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';

/*
    Input component
*/
class InputField extends React.Component {
    constructor(props) {
        super(props);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleOnFocusPassword = this.handleOnFocusPassword.bind(this);
        this.handleFocusOutPassword = this.handleFocusOutPassword.bind(this);
        this.state = {
            isPasswordVisible: false,
            passwordStyle: "password-label"
        }

    }

    handleClickShowPassword() {
        this.setState({isPasswordVisible: !this.state.isPasswordVisible});
    }

    handleOnFocusPassword() {
        this.setState({passwordStyle: "focus-password-label"});
    }

    handleFocusOutPassword(){
        this.setState({passwordStyle: "password-label"});
    }

    /**  form: {getFieldDecorator} = "",
     placeholder,
     password,
     id,
     error,
     isRequired,
     type,
     pattern,
     noForm,
     onChange,
     allowClear,
     defaultValue,
     initialValue
     }) =>**/

    render() {
        return this.props.noForm ? (
            this.props.password ? (
                <div>
                    <InputLabel
                                htmlFor="standard-adornment-password"
                                className={this.state.passwordStyle + " MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined"}
                    >Password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={
                          this.state.isPasswordVisible ? 'text' : 'password'
                        }
                        onFocus={this.handleOnFocusPassword}
                        onBlur={this.handleFocusOutPassword}
                        className={"login-password"}
                        // style={{marginBottom: 21, width: "328px",height:"56px"}}
                        // labelWidth={75}
                        onChange={this.props.onChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    edge="end"
                                >
                                    {this.state.isPasswordVisible ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </div>
            ) : (
              <>
                <TextField
                    id="standard-helperText"
                    label={this.props.placeholder}
                    style={this.props.styles}
                    InputLabelProps={{
                        shrink: true,
                        width: "80"
                    }}
                    type={ this.props.phoneNumber ? 'tel' :
                     this.props.numeric ? 'number' : 'text'
                    }
                    autocomplete={this.props.typePassword ? "off" : ''}
                    variant="outlined"
                    className="input-field"
                    onChange={this.props.onChange}
                    defaultValue={this.props.value}
                    disabled={this.props.disabled}
                />
              </>
            )
        ) : (
            <Form.Item>
                {this.props.form.getFieldDecorator(this.props.id, {
                    initialValue: this.props.initialValue,
                    rules: [
                        {
                            required: this.props.isRequired,
                            message: this.props.error,
                            type: this.props.type,
                            pattern: this.props.pattern,
                        },
                    ],
                })
                (
                    this.props.password ? (
                        <div>
                            <InputLabel
                                htmlFor="standard-adornment-password"
                                className={this.state.passwordStyle + " MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined"}
                            >{this.props.placeholder}</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={
                                  this.state.isPasswordVisible ? 'text' : 'password'
                                }
                                onFocus={this.handleOnFocusPassword}
                                onBlur={this.handleFocusOutPassword}
                                style={{width: "100%"}}
                                onChange={this.props.onChange}
                                 labelWidth={75}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickShowPassword}
                                            edge="end"
                                            style={{marginTop:"3px"}}
                                        >
                                            {this.state.isPasswordVisible ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </div>
                    ) : (

                        <TextField
                            id="standard-helperText"
                            label={this.props.placeholder}
                            InputLabelProps={{
                                shrink: true,
                                classes: {root: "test"}
                            }}
                            variant="outlined"
                            style={{width: "100%"}}
                            onChange={this.props.onChange}
                            type={ this.props.phoneNumber ? 'tel' :
                             this.props.numeric ? 'number' : 'text'
                            }
                            placeholder={this.props.inputPlaceholder}
                        />
                    )
                )}
            </Form.Item>
        )
    }
}

export default InputField
