// Import dependencies
import React from "react";
import { Link, navigate } from "gatsby";

// Import components
import MobileMenu from "./mobileMenu";

// Import styles
import "./header.css";

// Import icons
import Logo from "../../assets/icons/logo_white_nav.svg";
import Burger from "../../assets/icons/burger.svg";
import User from "../../assets/icons/user.svg";
import HelpOutline from "../../assets/icons/help-outline.svg";
import Avatar from "../../assets/images/avatar.png"
import { getFirebase } from "../../api/firebase"

// Import redux
import { connect } from "react-redux";
import { Badge } from "../common/badge/badge";

/*
    Desktop header component
*/
  class HeaderDesktop extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        menuStyles: "mobile-menu__content",
        menuBackgroundStyle: "ant-drawer ant-drawer-left menu-background",
        HomeIcons: false,
        profil_img: "none"
      };
    }

    toggleMenu = (status, statusBackground) => {
      this.setState({ menuStyles: status, menuBackgroundStyle: statusBackground });
    };

    componentDidMount() {
      this.setState({ isLoading: true })
      // Fetch user data
      if (this.props.loggedIn) {

        const app = import("firebase/app")
        const firestore = import("firebase/firestore")
    
        Promise.all([app, firestore]).then(([firebase]) => {
          getFirebase(firebase)
            .firestore()
            .collection("users")
            .doc(this.props.userToken.uid)
            .onSnapshot(doc => {
              // Get data and store in state
              let data = doc.data()
              this.setState({
                profil_img: data && data.account_info && data.account_info.profil_img ? data.account_info.profil_img : Avatar,
            })
          });
        });

      } else {
        this.setState({profil_img: Avatar})
      }
  
        
  
  
    }

    render() {
      const { cart, generalCart, loggedIn, shopPage } = this.props;
      const totalCartCount = cart.length + generalCart.length;

      return (
      <div className="header__content header__content--desktop">
        <Burger
          className="header__logo-mobile header__burger"
          onClick={() =>
            this.toggleMenu(
              "mobile-menu__content mobile-menu__content-opened ant-drawer ant-drawer-left ant-drawer-open",
              "ant-drawer ant-drawer-left ant-drawer-open menu-background"
            )
          }
          fill="red"
        />
          <Link
            to="/"
            className="header__link"
            activeClassName="header__link-active"
            aria-label="Home"
          >
            <Logo className="header__logo-mobile"/>
          </Link>

          <div className="shop_switch">
              <span className={!shopPage && "selected"} onClick={() => navigate("/")}>Recycle</span>
              <span className={shopPage && "selected"} onClick={() => navigate("/shop")}>Shop</span>
            </div>

          <div className="header__navigation-component">
              {(this.state.HomeIcons) ? <div className="header__bar-divider"></div> : "" }
              { !loggedIn &&
                <Link
                  to="/login"
                  className="header_navigation-icons"
                  aria-label="Help Center"
                >
                  <User className="header__square-link--mobile" />
                </Link>
              }
            <div
              className="header__square-link--mobile"
              onClick={this.props.onClick}
            >
              <div className="header__square-link--mobile">
                <span className="badge ant-badge">
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="#697386"><g><path d="M0 1.24954C0 1.93678 0.562291 2.49907 1.24954 2.49907H2.49907L6.9974 11.983L5.31053 15.0319C4.39837 16.7063 5.59792 18.743 7.49722 18.743H21.2421C21.9294 18.743 22.4916 18.1807 22.4916 17.4935C22.4916 16.8063 21.9294 16.244 21.2421 16.244H7.49722L8.87171 13.7449H18.1807C19.1179 13.7449 19.9426 13.2326 20.3674 12.4579L24.8408 4.34838C25.3031 3.52369 24.7033 2.49907 23.7537 2.49907H5.26055L4.42336 0.712235C4.22343 0.274898 3.7736 0 3.29877 0H1.24954C0.562291 0 0 0.562291 0 1.24954ZM7.49712 19.9926C6.12263 19.9926 5.01054 21.1172 5.01054 22.4917C5.01054 23.8662 6.12263 24.9907 7.49712 24.9907C8.87161 24.9907 9.99619 23.8662 9.99619 22.4917C9.99619 21.1172 8.87161 19.9926 7.49712 19.9926ZM17.506 22.4917C17.506 21.1172 18.6181 19.9926 19.9926 19.9926C21.3671 19.9926 22.4916 21.1172 22.4916 22.4917C22.4916 23.8662 21.3671 24.9907 19.9926 24.9907C18.6181 24.9907 17.506 23.8662 17.506 22.4917Z"></path></g></svg>
                  <sup data-show="true" className="ant-scroll-number ant-badge-count" title="1" style={{"background": "rgb(77, 144, 247)","padding": "0px 8.5px","flex": "0 0 24px"}}>{totalCartCount}</sup>
                </span>
              </div>
            </div>
            
              {loggedIn &&
                <Link
                  to="/account"
                  className="navbar_avatar_container"
                >
                  { this.state.profil_img == "none" 
                    ? <div className="navbar_avatar"></div>
                    : <img src={this.state.profil_img} className="navbar_avatar"/>
                  }
                  <span>1</span>
                </Link>
              }
          </div>

          <MobileMenu
            onClick={this.toggleMenu}
            styles={this.state.menuStyles}
            backgroundStyles={this.state.menuBackgroundStyle}
            isMember={this.props.isMember}
          />
        </div>
      );
    }
  }

// Connect redux to component
const mapStateToProps = state => ({
  cart: state.cart,
  generalCart: state.generalCart,
  loggedIn: state.user.isLoggedIn,
  userToken: state.user.userToken,
});

export default connect(mapStateToProps)(HeaderDesktop);
