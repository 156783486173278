// Import dependencies
import React from "react"

// Import styles
import "./social-media.css"

/*
    Main button component
*/
const SocialMedia = ({ icon, title, colour, onClick }) => (
  <div
    className="social-media__container"
    style={{ backgroundColor: colour }}
    onClick={onClick}
  >
    {icon}
    {title}
  </div>
)

export default SocialMedia
