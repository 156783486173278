import React from "react";
import anime from "animejs";

import "./loader.css";

const CLASS = "loader";

// show only once
let isShowLoaderFirst = false;

/**
 * Component to show Loader
 * Loader show only once except pages which has awaitData
 * Loader used in Layout component which unmount on every pages and for detect first render we need flag isShowLoaderFirst
 * @param awaitData - Await some data and then hide loader
 * @param hasAwaiter - Detect page which await some data, if awaitData used hasAwaiter need to true
 */
const Loader = ({ awaitData = false, hasAwaiter = false }) => {
  const [show, setShow] = React.useState(true);

  React.useEffect(() => {
    if (awaitData) return;

    anime({
      targets: `.${CLASS}`,
      opacity: "0",
      delay: 600, // await render content (actual for main page)
      complete: () => {
        // hide loader
        setShow(false);

        isShowLoaderFirst = true;
      },
    });
  }, [awaitData]);

  // some pages need show loader always (if awaitData), other pages after first show doesnt need show again
  if (!show || (isShowLoaderFirst && !hasAwaiter)) return null;

  return (
    <div className={CLASS}>
      <div className="dots">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="goo">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="12"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
              result="goo"
            />
            <feBlend in="SourceGraphic" in2="goo" />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default React.memo(Loader);
