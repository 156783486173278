// Import dependencies
import React from "react"
import { Spin } from "antd"

// Import components
import { TitleText, ParagraphText } from "../../styled-components/UILibrary"
import InputField from "../form/input/inputField"
import GeneralButton from "../form/buttons/GeneralButton"
import SocialMedia from "../form/buttons/socialMedia"

// Import styles
import "./login.css"
import "./register.css"
import { Constants } from "../../constants/Constants"

// Import icons
import Facebook from "../../assets/icons/facebook-white.svg"
import Google from "../../assets/icons/google-white.svg"

// Import from Redux
import { connect } from "react-redux"
import { logUserIn } from "../../redux/actions/userAction"

// Ger firebase API
import { getFirebase } from "../../api/firebase"

// Import utils
import {
  generatePassComplexityError,
  PASSWORD_REGEX,
} from "./utils/registerUtils"

/*
    Register component
*/
class RegisterComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      error: null,
      isLoading: false,
    }
    this.updateEmail = this.updateEmail.bind(this)
    this.updatePassword = this.updatePassword.bind(this)
    this.updateConfirmPassword = this.updateConfirmPassword.bind(this)
    this.sendRegisterRequest = this.sendRegisterRequest.bind(this)
  }

  updateEmail = e => {
    this.setState({ email: e.target.value })
  }
  updatePassword = e => {
    this.setState({ password: e.target.value })
  }
  updateConfirmPassword = e => {
    this.setState({ confirmPassword: e.target.value })
  }

  sendRegisterRequest = () => {
    // Reset errors
    this.setState({ error: null, isLoading: true })

    // Check if passwords match
    if (this.state.password === this.state.confirmPassword) {
      if (this.state.password.match(PASSWORD_REGEX)) {
        const app = import("firebase/app")
        const auth = import("firebase/auth")
        const firestore = import("firebase/firestore")

        Promise.all([app, auth, firestore]).then(([firebase]) => {
          getFirebase(firebase)
            .auth()
            .createUserWithEmailAndPassword(
              this.state.email,
              this.state.password
            )
            .then(() => {
              let user = firebase.auth().currentUser
              if (user) {
                // Create user profile object
                const userProfile = {
                  account_info: {
                    title: null,
                    first_name: null,
                    last_name: null,
                    email: user.email,
                    phone: null,
                    profil_img: null,
                  },
                  billing_info: {
                    apt_suite_etc: null,
                    city: null,
                    country: null,
                    home_address: null,
                    postal_code: null,
                  },
                  contact_info: {
                    apt_suite_etc: null,
                    city: null,
                    country: null,
                    home_address: null,
                    postal_code: null,
                  },
                  membership_info: {
                    is_member: false,
                    start_date: null,
                    end_date: null,
                    stripe_customer_id: null,
                    stripe_subscription_id: null,
                    membership_id: null,
                  },
                  premium_orders: [],
                  general_orders: [],
                }

                // Create Firestore user record
                firebase
                  .firestore()
                  .collection("users")
                  .doc(user.uid)
                  .set(userProfile)
                  .then(() => {
                    this.props.dispatch(logUserIn(user))
                    this.setState({ isLoading: false })
                  })
                  .catch(() =>
                    this.setState({
                      error:
                        "There was an issue creatig a new user. Please try again.",
                      isLoading: false,
                    })
                  )
              }
            })
            .catch(error => {
              // Handle Errors here.
              this.setState({ error: error.message, isLoading: false })
            })
        })
      } else {
        this.setState({
          error: generatePassComplexityError(),
          isLoading: false,
        })
      }
    } else {
      this.setState({ error: "Passwords do not match.", isLoading: false })
    }
  }

  registerWithGoogle = () => {
    // Reset errors
    this.setState({ error: null, isLoading: true })

    const app = import("firebase/app")
    const auth = import("firebase/auth")
    const firestore = import("firebase/firestore")

    Promise.all([app, auth, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .auth()
        .signInWithPopup(new firebase.auth.GoogleAuthProvider())
        .then(result => {
          // Create user profile object
          const userProfile = {
            account_info: {
              title: null,
              first_name: result.user.displayName.split(" ")[0],
              last_name: result.user.displayName.split(" ")[1],
              email: result.user.email,
              phone: null,
            },
            billing_info: {
              apt_suite_etc: null,
              city: null,
              country: null,
              home_address: null,
              postal_code: null,
            },
            contact_info: {
              apt_suite_etc: null,
              city: null,
              country: null,
              home_address: null,
              postal_code: null,
            },
            membership_info: {
              is_member: false,
              start_date: null,
              end_date: null,
              stripe_customer_id: null,
              stripe_subscription_id: null,
              membership_id: null,
            },
            premium_orders: [],
            general_orders: [],
          }

          // Create Firestore user record
          firebase
            .firestore()
            .collection("users")
            .doc(result.user.uid)
            .set(userProfile)
            .then(() => {
              this.props.dispatch(logUserIn(result.user))
              this.setState({ isLoading: false })
            })
            .catch(() =>
              this.setState({
                error:
                  "There was an issue creatig a new user. Please try again.",
                isLoading: false,
              })
            )
        })
        .catch(error => {
          this.setState({ error: error.message, isLoading: false })
        })
    })
  }

  registerWithFacebook = () => {
    // Reset errors
    this.setState({ error: null, isLoading: true })

    const app = import("firebase/app")
    const auth = import("firebase/auth")
    const firestore = import("firebase/firestore")

    Promise.all([app, auth, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .auth()
        .signInWithPopup(new firebase.auth.FacebookAuthProvider())
        .then(result => {
          // The signed-in user info.
          var user = result.user

          // Create user profile object
          const userProfile = {
            account_info: {
              title: null,
              first_name: user.displayName.split(" ")[0],
              last_name: user.displayName.split(" ")[1],
              email: user.email,
              phone: user.phoneNumber,
            },
            billing_info: {
              apt_suite_etc: null,
              city: null,
              country: null,
              home_address: null,
              postal_code: null,
            },
            contact_info: {
              apt_suite_etc: null,
              city: null,
              country: null,
              home_address: null,
              postal_code: null,
            },
            membership_info: {
              is_member: false,
              start_date: null,
              end_date: null,
              stripe_customer_id: null,
              stripe_subscription_id: null,
              membership_id: null,
            },
            premium_orders: [],
            general_orders: [],
          }

          // Create Firestore user record
          firebase
            .firestore()
            .collection("users")
            .doc(result.user.uid)
            .set(userProfile)
            .then(() => {
              this.props.dispatch(logUserIn(result.user))
              this.setState({ isLoading: false })
            })
            .catch(() =>
              this.setState({
                error:
                  "There was an issue creatig a new user. Please try again.",
                isLoading: false,
              })
            )
        })
        .catch(error => {
          this.setState({ error: error.message, isLoading: false })
        })
    })
  }

  render() {
    return (
      <div
        className="account_form_container"
        style={{ maxHeight: "calc(100vh - 200px)", overflowY: "scroll" }}
      >
        <TitleText subTitle center margin="margin-bottom: 15px">
          Hey there, stranger
        </TitleText>

        <ParagraphText center>Continue with</ParagraphText>
        <div className="register__social-media">
          <SocialMedia
            title="Facebook"
            colour="#45619E"
            icon={<Facebook className="social-media__icon" />}
            onClick={this.registerWithFacebook}
          />
          <div className="register__button-separator" />
          <SocialMedia
            title="Google"
            colour="#4688F1"
            icon={<Google className="social-media__icon" />}
            onClick={this.registerWithGoogle}
          />
        </div>

        <ParagraphText center margin="margin-bottom: 10px">
          Or create manually
        </ParagraphText>
        <InputField placeholder="Email" noForm onChange={this.updateEmail} />
        <InputField
          placeholder="Password"
          password
          noForm
          onChange={this.updatePassword}
        />

        {this.state.error && (
          <ParagraphText
            colour={Constants.mainRed}
            margin="margin-bottom: 10px"
          >
            {this.state.error}
          </ParagraphText>
        )}

        <div className="login__button" onClick={this.sendRegisterRequest}>
          <GeneralButton
            title="SIGN UP"
            type="primary"
            fit
            margin={{ marginTop: 10 }}
          />
        </div>

        {this.state.isLoading ? (
          <div className="login__loader">
            <Spin size="large" />
          </div>
        ) : null}
      </div>
    )
  }
}

// Connect redux to component
export default connect()(RegisterComponent)
