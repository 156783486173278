// Import dependencies
import React from "react"
import { Modal } from "antd"

// Import components
import { NavLink } from "../../styled-components/UILibrary"

// Import styles
import "./legals-and-disclaimers.css"

// Import data
import {
  TermsAndConditions,
  PrivacyPolicy,
  Cookies,
} from "../../data/legalsAndDisclaimers"

/*
    Legals & Disclaimers modal
*/
export default class LegalsAndDisclaimers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: "terms-and-conditions",
    }
    this.changePage = this.changePage.bind(this)
  }

  changePage = page => {
    this.setState({ currentPage: page })
  }

  render() {
    return (
      <Modal
        centered
        visible={this.props.visible}
        onOk={() => this.props.closeModal(false)}
        onCancel={() => this.props.closeModal(false)}
        bodyStyle={{
          padding: "50px 60px",
          maxHeight: "calc(95vh - 80px)",
          overflow: "scroll",
        }}
        maskStyle={{
          backgroundColor: "rgba(255,255,255,0.90)",
        }}
        footer={null}
        width='84vw'
        destroyOnClose
      >
        <div className="lad__links">
          <NavLink
            selected={this.state.currentPage === "terms-and-conditions"}
            onClick={() => this.changePage("terms-and-conditions")}
          >
            Terms & Conditions
          </NavLink>
          <NavLink
            margin="margin: 0 30px"
            selected={this.state.currentPage === "privacy-policy"}
            onClick={() => this.changePage("privacy-policy")}
          >
            Privacy Policy
          </NavLink>
          <NavLink
            selected={this.state.currentPage === "cookies"}
            onClick={() => this.changePage("cookies")}
          >
            Cookies
          </NavLink>
        </div>

        <div className="lad__content">
          {/* <TermsAndConditions /> */}
          {this.state.currentPage === "terms-and-conditions" ? (
            <TermsAndConditions />
          ) : this.state.currentPage === "privacy-policy" ? (
            <PrivacyPolicy />
          ) : this.state.currentPage === "cookies" ? (
            <Cookies />
          ) : null}
        </div>
      </Modal>
    )
  }
}
